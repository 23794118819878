/*.App {
  overflow: hidden;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #161D31;
}
.App header {
  position: absolute;
  width: 100vw;
  height: 11.57407407vh;
  background-color: black;
}*/

/*.App-logo {
  position: absolute;
  top: 3.5vh;
  left: 3vw;
}
.App-logo-Txt {
  position: absolute;
  top: 4vh;
  left: 10vw;
}
.App-logo-ConnectWallet {
  position: absolute;
  top: 3.5vh;
  left: 87vw;
}
.showAccount-container {
  position: absolute;
  height: 4.8vh;
  width: 9.5vw;
  border-radius: 30px;
  background-color: #6cc4a9;
  color: white;
  font-weight: 750;
  font-size: 20px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  top: 3.5vh;
  left: 87vw;
}
.popupInstall-container {
  position: absolute;
  height: 4.8vh;
  width: 30.5vw;
  background-color: black;
  color: #6cc4a9;
  font-weight: 750;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 13.5vh;
  left: 67vw;
  z-index: 1;
}

.logo-title {
  position: absolute;
  top: 30vh;
  left: 14vw;
  z-index: 1;
}
.logo-article-txt {
  position: absolute;
  top: 40vh;
  left: 14vw;
  z-index: 1;
}
.logo-button-lorem{
  position: absolute;
  top: 52vh;
  left: 14vw;
  z-index: 1;
}
.logo-button-vote {
  position: absolute;
  top: 52vh;
  left: 26vw;
  z-index: 1;
}
.popup-container {
  position: absolute;
  height: 4.8vh;
  width: 20.5vw;
  background-color: black;
  color: #6cc4a9;
  font-weight: 750;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 52vh;
  left: 40vw;
  z-index: 1;
}

.footer-background {
  width: 100vw;
  position: absolute;
  top: 10.7vh; 
}*/


.ql-editor {
  font-family: 'FiraGO', sans-serif; /* Default font for the editor */
  background-color: rgb(33 37 41);
  height: 600px;
  color: white !important;
}

.quill-container ul,
.quill-container ol {
  padding-left: 40px; /* Adds indentation for list items */
}

.quill-container li {
  list-style: disc inside; /* Ensures bullet points are visible for <ul> */
  margin-bottom: 5px; /* Space between list items */
}