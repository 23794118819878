/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

body {
  font-family: 'FiraGO', sans-serif;
  font-weight: 400; /* You can set a default weight, adjust as needed */
  font-style: normal; /* Set to italic if you want default italic */
}

h1, h2, h3, h4, h5, h6, p, a, span, div, button, input, textarea {
  font-family: 'FiraGO', sans-serif;
  font-weight: inherit;
  font-style: inherit;
}


/* firago-latin-100-normal */
@font-face {
  font-family: 'FiraGO';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-100-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-100-normal.woff) format('woff');
}

/* firago-latin-200-normal */
@font-face {
  font-family: 'FiraGO';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-200-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-200-normal.woff) format('woff');
}

/* firago-latin-300-normal */
@font-face {
  font-family: 'FiraGO';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-300-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-300-normal.woff) format('woff');
}

/* firago-latin-400-normal */
@font-face {
  font-family: 'FiraGO';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-400-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-400-normal.woff) format('woff');
}

/* firago-latin-500-normal */
@font-face {
  font-family: 'FiraGO';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-500-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-500-normal.woff) format('woff');
}

/* firago-latin-600-normal */
@font-face {
  font-family: 'FiraGO';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-600-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-600-normal.woff) format('woff');
}

/* firago-latin-700-normal */
@font-face {
  font-family: 'FiraGO';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-700-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-700-normal.woff) format('woff');
}

/* firago-latin-800-normal */
@font-face {
  font-family: 'FiraGO';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-800-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-800-normal.woff) format('woff');
}

/* firago-latin-900-normal */
@font-face {
  font-family: 'FiraGO';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-900-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/firago@latest/latin-900-normal.woff) format('woff');
}

.custom-accordion-style-1 > .card .card-header a:hover {
  background-color: #809dae !important;
}

.ql-toolbar.ql-snow {
  background-color: whitesmoke;
}

/* .ql-editor {
  font-family: 'FiraGO', sans-serif;
  background-color: rgb(33 37 41);
  height: 600px;
  color: white !important;
} */

.quill-container ul,
.quill-container ol {
  padding-left: 40px; /* Adds indentation for list items */
}

.quill-container li {
  list-style: disc inside; /* Ensures bullet points are visible for <ul> */
  margin-bottom: 5px; /* Space between list items */
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: transparent !important;
}

.nav {
  flex-wrap: nowrap !important;
}

@media (min-width: 991px) and (max-width: 1215px) {
  .nav {
    font-size: 14px;
  }
}