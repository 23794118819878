.myModal {
    position: fixed;
    width: 300px;  
    /* height: 60%; */
    padding-top: 15px;
    padding-bottom: 15px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
.myModal-close-icon{
    width: 3vw;
    height: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;     
}
@media only screen and (max-width: 1000px) {
    .myModal-close-icon{
        width: 30px;
        height: 30px;
    }
}
.myModal-close-icon>span {
    position: absolute;
    width: 1.5vw;
    height: 0.5px;
    min-width: 10px;
    background: gray;
}
.myModal-close-icon>:first-child{
transform: rotate(45deg);
}
.myModal-close-icon>:last-child {
    transform: rotate(-45deg);
}
@media only screen and (max-width: 1000px) {
    .myModal-close-icon>span{width: 15px}
}
.label-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5%;
    height: 61px;
    justify-content: space-between;
}
.label-input-container label{
    color: white;
    font-weight: 100;
}
.form-button {
    width: 109px;
    margin-top: 15%;
    background-color: #2f5e8e;
    border: none;
    border-radius: 5px;
    color: white;
}